// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import {
	HEADER_ITEMS_GROUP_LOAD_EXPERIENCE,
	getExperienceTracker, // eslint-disable-line no-restricted-imports
} from '@confluence/experience-tracker';

const TIMEOUT_DURATION = 5000;

export class HeaderItemsContainer extends Container {
	state = {
		items: [],
		itemsLoaded: false,
	};
	loadTimeout = null;

	addItem = (newkey) => {
		if (this.state.items.filter((elem) => elem.key === newkey).length) return false;
		const newItem = { key: newkey, loaded: false };
		this.state.items.push(newItem);
		return true;
	};

	getItem = (key) => {
		const itemState = this.state.items.find((item) => item.key === key);
		return itemState ? { ...itemState } : undefined;
	};

	markItem = (key) => {
		if (this.getItem(key)?.loaded) {
			return Promise.resolve();
		}

		return this.setState((state) => {
			return {
				items: state.items.map((item) => {
					if (item.key !== key) return item;
					else return { key: item.key, loaded: true };
				}),
			};
		}, this.checkAllItems);
	};

	checkAllItems = () => {
		let result = true;
		if (this.state.items.length) {
			this.state.items.forEach((elem) => {
				if (!elem.loaded) result = false;
			});

			if (result) {
				getExperienceTracker().succeed({
					name: HEADER_ITEMS_GROUP_LOAD_EXPERIENCE,
				});

				if (!this.state.itemsLoaded) {
					this.setState(() => {
						return {
							itemsLoaded: true,
						};
					});

					clearTimeout(this.loadTimeout);
				}
			}
		}
	};

	getAllItemsLoadState = () => {
		return this.state.itemsLoaded;
	};

	findLoadIncompleteItems = () => {
		return this.state.items.filter((item) => !item.loaded).map((item) => item.key);
	};

	initItems = () => {
		this.state.items = [];
		this.state.itemsLoaded = false;
		clearTimeout(this.loadTimeout);
		this.startTimer();
		getExperienceTracker().start({
			name: HEADER_ITEMS_GROUP_LOAD_EXPERIENCE,
		});
	};

	startTimer = () => {
		this.loadTimeout = setTimeout(() => {
			const inCompletedKeys = this.findLoadIncompleteItems();
			getExperienceTracker().fail({
				name: HEADER_ITEMS_GROUP_LOAD_EXPERIENCE,
				error: new Error(`Grouped Page Buttons loading exceeded ${TIMEOUT_DURATION} milliseconds`),
				attributes: {
					incompleteItems: inCompletedKeys.join(','),
				},
			});
			this.setState(() => {
				return {
					itemsLoaded: true,
				};
			});
		}, TIMEOUT_DURATION);
	};
}

export enum HEADER_ITEM_KEYS {
	SHARE_PAGE = 'share-page',
	PAGE_RESTRICTIONS = 'page-restrictions',
	COPY_LINK = 'copy-link',
	CONTENT_TOOLS = 'content-tools',
	PAGE_FAVOURITE = 'page-favourite',
	WATCH_PAGE = 'watch-page',
	EDIT_PAGE = 'edit-page',
	PAGE_COMMENTS = 'page-comments',
	OPEN_EMBEDDED_LINK = 'open-embedded-link',
	RECORD_LOOM = 'record-loom',
}
